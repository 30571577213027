<template>
    <div class="confirmation-page" dir="rtl">
        <h3 class="text-center font-bold mb-4">الشروط والأحكام</h3>
        <div class="text-justify">
            <p>موقع مُختصر الإلكتروني <a href="https://www.muktasar.com">www.muktasar.com</a> يمكن الوصول إليه عن طريق
                الإنترنت يوفر لكم المحتويات والخدمات
                المتوفرة على الموقع حسب الأحكام والشروط التالية وسياسة الخصوصية لدينا وأحكام وشروط وسياسات أخرى قد
                تجدونها من خلال موقعنا بشأن وظائف محددة، مزايا أو عروض أو خدمة العملاء، التي تعتبر جميعها جزءا من أو
                متضمنة في هذه الأحكام والشروط (عامة، "الأحكام والشروط").</p>
            <p>
                دخولكم إلى الموقع أو استخدامه هو بمثابة إقرار منكم بأنكم قد قرأتم هذه الأحكام والشروط وفهمتموها ووافقتم
                على الالتزام بها التزاما كاملا دون قيد أو شرط
            </p>


            <h6 class="font-bold mt-5">الملكية الفكرية</h6>
            <p>
                نحن نمنحك صلاحية شخصية غير قابلة للتحويل أو التنازل وقابلة للإلغاء لاستخدام البرامج التي نوفرها لك كجزء
                من الموقع الإلكتروني، وذلك لغرض واحد يتمثل في تمكينك من استخدام خدماتنا والاستمتاع بمزاياها، وهذا على
                النحو المقرر من قبل مُختصر ووفق الطريقة التي تسمح بها الشروط والأحكام.
            </p>
            <p>ويحظر الاستخدام التجاري أو الاستخدام نيابةً عن الغير، ما لم نسمح بذلك صراحةً وخطيًا بصورة مسبقة. ويترتب
                على أي خرق لهذه الشروط والأحكام الإلغاء الفوري للرخصة الممنوحة بمقتضى هذه الفقرة دون سابق إنذار لك، ودون
                المساس بأي حقوق أخرى.</p>
            <p>
                جميع حقوق النشر والعلامات التجارية والأسماء التجارية والمظهر التجاري والتصميمات و"سمات" الموقع
                الإلكتروني
                وأي شكل من الأشكال الأخرى للملكية الفكرية والمواد والحقوق الأخرى المرتبطة بالموقع، بما في ذلك البرامج
                وكود إتش تي إم إل وغيره من الأكواد المستخدمة في الموقع الإلكتروني أو عناوين الصفحات أو الصور أو النصوص
                أو الرسوم التوضيحية أو التنسيقات أو الشعارات أو التصاميم أو الأيقونات أو الصور أو البرامج أو المقاطع
                الموسيقية أو التنزيلات أو مقاطع الفيديو (إجمالاً، "الملكية الفكرية")، هي — وتظل في جميع الأوقات— ملكية
                وحقًا أصيلاً مُختصر/ أو هي ملكية لصاحب كل منها، كما أنها محمية من قبل المملكة العربية السعودية وقوانين
                العلامات التجارية وحقوق التأليف والنشر الدولية.
            </p>
            <p>
                ويجوز الوصول إلى الملكية الفكرية وجميع المواد الأخرى المعروضة على موقعنا الإلكتروني وعرضها فقط للاستخدام
                الشخصي غير التجاري، وذلك حسبما تجيزه صراحةً مُختصر و/ أو المرخَّص لهم كأطراف ثالثة من جانب مُختصر.
            </p>
            <p>
                ولكن (1) بشرط ألا تجري أي تعديل أو تغيير على الملكية الفكرية بأي شكل من الأشكال، و(2) ألَّا تقدم أو تتيح
                الملكية الفكرية لأي طرف ثالث كنوع من التجارة. ولن تكتسب أي حق من حقوق التأليف والنشر أو العلامات
                التجارية أو غيرها من حقوق الملكية على الإطلاق فيما يتعلق بالملكية الفكرية. ولا يؤول لك أي ترخيص أو حق أو
                مصلحة في أي مواد أو برامج كأثر مترتب على استخدامك لهذا الموقع الإلكتروني أو على وصولك إلى الملكية
                الفكرية أو عرضها أو تنزيلها أو طباعتها.
            </p>
            <p>
                ولا يجوز لك نسخ (باستثناء ما هو مبين أعلاه) أو نشر أو بث أو توزيع أو عرض أو تعديل أو إنشاء أعمال مشتقة
                من أي من مواد الملكية الفكرية أو من موقعنا الإلكتروني أو بيعها أو المشاركة في أي بيع أو استغلال لها بأي
                شكل من الأشكال، سواء كليًا أو جزئيًا. ولا يجوز استخدام الملكية الفكرية إلا باعتبارها مقدم لخدمة اختصارات
                الكتب العالمية. ويُمنَع منعًا باتًا أي استخدام آخر للملكية الفكرية، بما في ذلك نسخها أو تعديلها أو
                توزيعها أو بثها أو إعادة نشرها أو عرضها أو أدائها.
            </p>
            <p>مُختصر هو المالك الحصري أو المرخَّص له للملكية الفكرية بشكل حصري (حسب مقتضى الحال)، وهو المرخَّص له بشكل
                حصري لاسم النطاق <a href="https://www.muktasar.com">www.muktasar.com</a></p>
            <p>يجوز لمُختصر أن يوفر محتوى مملوكًا لأطراف ثالثة ("محتوى أطراف ثالثة") أو روابط لمواقع إلكترونية لأطراف
                ثالثة ("مواقع أطراف ثالثة") كخدمة للمهتمين بهذه المعلومات. ولا يراقب مُختصر أي محتوى لأطراف ثالثة أو
                مواقع أطراف ثالثة ولا يصدق على محتواها وليس لديها أي سيطرة عليها، ولا يعني إدراج روابط لمحتوى أطراف
                ثالثة أو لمواقع أطراف ثالثة وجود أي ارتباط أو علاقة بين مُختصر والأطراف الثالثة ذات الصلة.</p>
            <p>
                ولا يتضمن مُختصر أو يقر بدقة أو كمال أي محتوى أطراف ثالثة أو أي مواقع أطراف ثالثة ولا يصادق على مدى
                دقتها أو كمالها. كما أن مُختصر ليس مسؤول عن تحديث أو مراجعة محتوى الأطراف الثالثة أو مواقع الأطراف
                الثالثة. ويكون استخدامك لمحتوى الأطراف الثالثة ومواقع الأطراف الثالثة على مسؤوليتك. ولا يعكس بالضرورة
                محتوى الأطراف الثالثة، بما في ذلك تعليقات مستخدمي محتوى الأطراف الثالثة التي ترد إلى مُختصر، وجهات نظر
                مُختصر الخاصة.
            </p>
            <p>في ضوء ما سبق، لن يتحمل مُختصر المسؤولية لأي سبب كان على الإطلاق عن محتوى الأطراف الثالثة و/ أو مواقع
                الأطراف الثالثة، بما يشمل على سبيل المثال لا الحصر، توفير مصادر خارجية أو المحتوى أو الإعلانات التجارية
                أو المنتجات أو الخدمات أو أي معلومات أو بيانات أخرى متوفرة على الموقع الإلكتروني أو من مصادر خارجية.</p>


            <h6 class="font-bold mt-5">الخصوصية</h6>
            <p><a href="/p/privacy">الرجاء مراجعة سياسة الخصوصية لدينا لمعرفة تفاصيل حول ممارساتنا بشأن المعلومات
                الشخصية.</a></p>


            <h6 class="font-bold mt-5">معلومات الشراء</h6>
            <p>رسوم الاشتراك هي رسوم ثابتة شهرية يتم سحبها بشكل اوتوماتيكي من بطاقة الائتمان ويتم تحديدها حسب اتفاق
                الدفع الأولى الذي يتم وتسحب الرسوم مره في الشهر أو مره في السنة حسب الباقة المختارة من قبل العميل.</p>
            <p>لن تُخزن أي بيانات بطاقات ائتمان أو تباع إلى أو تُعرض على أو تؤجر لأي أطراف ثالثة باستثناء ما يتعلق بمقدم
                خدمة بوابة الدفع عبر الإنترنت الذي نستعين به.</p>


            <h6 class="font-bold mt-5">الإلغاء</h6>
            <p>ندرك تماماً أنه في بعض الأحيان قد تغير رأيك بخصوص الاشتراك. فيحق لكل مشترك إلغاء اشتراكه في أي وقت يرغب
                به وسيبقى اشتراكك فعال حتى تنتهي من الشهر الذي تم الاشتراك به. على سبيل المثال في حال اشتركت في تاريخ ٥
                من الشهر والغيت الاشتراك تاريخ ٤ من الشهر التالي فلن يتم سحب مبلغ الشهر القادم من حسابك وسوف يلغى
                اشتراكك فور اكتمال شهر من تاريخ الإنضمام.</p>


            <h6 class="font-bold mt-5">أحكام متفرقة</h6>
            <p>نحن نحتفظ بالحق في تعديل هذه الشروط والأحكام في أي وقت دون سابق إنذار لك. وتسري أي تعديلات على هذه الشروط
                والأحكام بمجرد نشرها على موقعنا الإلكتروني. ويعني استمرارك في استخدام الموقع الإلكتروني (أو أي من
                مواقعنا الإلكترونية الأخرى) بعد إجراء تغيير من هذا القبيل موافقتك على الالتزام بالشروط والأحكام المعدلة.
                ويُرجى قراءة الشروط والأحكام والاطلاع عليها مراراً وتكراراً. وفي حال لم تكن موافقاً على أي تغيير في
                الشروط والأحكام، يجب عليك عندئذ التوقف فوراً عن استخدام الموقع الإلكتروني.</p>
            <p>تشكل هذه الشروط والأحكام كامل الاتفاق القانوني بينك وبين مُختصر، وتحكم وتنظم استخدامك للموقع الإلكتروني
                وجميع الخدمات والمنتجات المتاحة عليه، وتحل تماماً محل أي اتفاقيات سابقة بينك وبين مُختصر فيما يتعلق
                بالغرض نفسه.</p>
            <p>
                أنت تقر وتوافق على أنه قد يتغير شكل وطبيعة الخدمات من وقت لآخر دون إشعار مُسبق لك، ويجوز لـ مُختصر
                التوقف عن تقديم الخدمات (أو عن تقديم أي ميزات مُضمَّنة فيها) لك أو للمستخدمين.
            </p>
            <p>أنت تتفهم وتدرك أن مُختصر يمنح مشغلي محركات البحث العامة الإذن باستخدام عناكب الشبكة لنسخ مواد من الموقع
                للغرض الوحيد المتمثل في إنشاء مؤشرات بحث متوفرة للجمهور تتضمن المواد، ويحتفظ مُختصر بالحق في إلغاء هذه
                الاستثناءات، سواء بوجه عام أو في حالات محددة.</p>
            <p>أنت تتفهم وتدرك أنك وحدك المسؤول (وأن مُختصر ليس مسؤول تجاهك أو تجاه أي طرف ثالث) عن أي خرق لالتزاماتك
                بموجب الشروط والأحكام وعن النتائج المترتبة (بما في ذلك أي خسارة أو ضرر قد تتكبد مُختصر) على أي خرق من
                هذا القبيل.</p>
            <p>
                أنت تتفهم وتدرك أنك وحدك المسؤول (وأن مُختصر ليس مسؤول تجاهك أو تجاه أي طرف ثالث) عن أي خرق لالتزاماتك
                بموجب الشروط والأحكام وعن النتائج المترتبة (بما في ذلك أي خسارة أو ضرر قد تتكبد مُختصر) على أي خرق من
                هذا القبيل.
            </p>
            <p>
                أنت ومُختصر متعاقدان مستقلان، وهذه الشروط والأحكام، بما يشمل على سبيل المثال لا الحصر تقديم أو نقل أو بث
                أو توزيع المحتوى الخاص بك، سواء كلياً أو جزئياً، لا تشكلان علاقة شراكة أو مشروعاً مشتركاً أو عملاً أو
                امتيازاً أو وكالة بينك وبين مُختصر. ولن يكون لأي طرف من الطرفين سلطة إلزام الطرف الآخر أو تحميله
                التزامات نيابةً عنه بدون موافقة خطية مُسبقة من الطرف الآخر. وكلا الطرفين غير مفوَّض بالتصرف كوكيل أو
                ممثل للطرف الآخر أو نيابةً عن الطرف الآخر بأي صفة أخرى غير منصوص عليها صراحةً في الشروط والأحكام. ولا
                يجوز لأي طرف (أو لأي من وكلائه) التصريح بأنه يمثل الطرف الآخر أو مخولاً للتصرف كممثل له أو اعتبار نفسه
                ممثلاً له، كما لا يجوز لأي طرف أن يحمِّل الطرف الآخر أي مسؤولية أو التزام نيابةً عنه أو باسمه، ما لم ينص
                على ذلك تحديداً في هذه الشروط والأحكام.
            </p>
            <p>إذا ثبت أن أي نص من نصوص هذه الشروط والأحكام غير قانوني أو غير قابل للتنفيذ، لا تتأثر الشروط والأحكام
                الأخرى وتظل نافذة وسارية المفعول تماماً.</p>
            <p>تخضع هذه الشروط والأحكام لقوانين المملكة العربية السعودية، وتخضع جميع المنازعات التي تنشأ عن إنفاذ هذه
                الأحكام والشروط للاختصاص القضائي الحصري لمحاكم المملكة العربية السعودية.</p>

            <h6 class="font-bold mt-5">حقوقك</h6>
            <p>إذا كان لديك أي أسئلة حول الشروط والأحكام أو أي جانب من جوانب النظام الخاص بك. نرجو منك التواصل معنا عبر
                البريد الالكتروني <a href="mailto:info@muktasar.com">info@muktasar.com</a></p>
        </div>
    </div>
</template>
<script>
export default {
    data() {
    },
}
</script>
<style>
a {
    text-decoration: none !important;
}
</style>